import { Component, ViewEncapsulation } from '@angular/core';
import {OnboardingIconConfiguration} from './models/onboarding-icon-configuration.interface';
import {OnboardingService} from './services/onboarding.service';


/**
 * onboarding button including context menu (see header.component in rolib/navigation)
 */
@Component({
    selector: 'rosen-onboarding-button',
    template: `<div class="onboarding-button-container">
    <mat-menu #onboardingMenu="matMenu">
        <button mat-menu-item (click)="enableOnboarding()" *ngIf="!isOnboardingEnabled()"
                class="onboarding-menu-button">
            <mat-icon>add_alert</mat-icon>
            {{'ONBOARDING_ENABLE'|translate}}
        </button>
        <button mat-menu-item (click)="disableOnboarding()" *ngIf="isOnboardingEnabled()"
                class="onboarding-menu-button">
            <mat-icon>notifications_off</mat-icon>
            {{'ONBOARDING_DISABLE'|translate}}
        </button>
        <button mat-menu-item (click)="clearOnboarding()" class="onboarding-menu-button">
            <mat-icon>undo</mat-icon>
            {{'ONBOARDING_CLEAR'|translate}}
        </button>
    </mat-menu>
    <button *ngIf="iconConfig" mat-icon-button [matMenuTriggerFor]="onboardingMenu" class="onboarding-button" [title]="'ONBOARDING'|translate">
        <mat-icon *ngIf="iconConfig.matIconName && !iconConfig.svgIcon && !iconConfig.fontSet"
                  [matBadgeColor]="iconConfig.matBadgeColor" [matBadgeHidden]="isOnboardingEnabled()" [matBadge]="onboardingItemCount"
                  [matBadgePosition]="iconConfig.matBadgePosition" [matBadgeSize]="iconConfig.matBadgeSize"
                  [matBadgeOverlap]="true">{{iconConfig.matIconName}}
        </mat-icon>
        <mat-icon *ngIf="iconConfig.svgIcon || iconConfig.fontSet"
                  [svgIcon]="iconConfig.svgIcon"
                  [fontSet]="iconConfig.fontSet" [fontIcon]="iconConfig.fontIcon"
                  [matBadgeColor]="iconConfig.matBadgeColor" [matBadgeHidden]="isOnboardingEnabled()" [matBadge]="onboardingItemCount"
                  [matBadgePosition]="iconConfig.matBadgePosition" [matBadgeSize]="iconConfig.matBadgeSize"
                  [matBadgeOverlap]="true">
        </mat-icon>
    </button>
</div>
`,
    styles: [`.onboarding-button-container{position:relative;text-align:center}.onboarding-button-container .mat-icon{font-size:32px;width:32px!important;height:32px!important;line-height:32px!important}.onboarding-button-container .onboarding-button svg{pointer-events:none}`],
    encapsulation: ViewEncapsulation.None
})
export class OnboardingButtonComponent {

    /** used by template to apply the customizations on icons */
    public iconConfig: OnboardingIconConfiguration;

    constructor(private onboardingService: OnboardingService) {
        const config = onboardingService.getConfiguration();
        if (config) {
            this.iconConfig = config.iconConfiguration;
        }
    }

    /**
     * if true, the count is visible
     * is true, if the onboarding service is disabled and at least one onboarding item is visible
     */
    public get showOnboardingItemCount(): boolean {
        return !this.onboardingService.isEnabled() &&
            this.onboardingService.visibleItems &&
            this.onboardingService.visibleItems.totalLength > 0;
    }

    /**
     * gets the visible item count
     */
    public get onboardingItemCount(): number {
        return this.onboardingService.visibleItems.totalLength;
    }

    /**
     * used by template
     * enables the onboarding service
     */
    public enableOnboarding(): void {
        this.onboardingService.enable();
    }

    /**
     * disables the onboarding service
     */
    public disableOnboarding(): void {
        this.onboardingService.disable();
    }

    /**
     * resets the onboarding service
     * removes all selectors from seen selectors
     */
    public clearOnboarding(): void {
        this.onboardingService.clearSeenSelectors();
    }

    /**
     * is true, if the onboarding service is enabled
     */
    public isOnboardingEnabled(): boolean {
        return this.onboardingService.isEnabled();
    }
}
